import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintBrush, faBriefcase, faLaptopCode, faMobileAlt, faQuoteLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { useForm, ValidationError } from '@formspree/react';
import './career.css';

const Careers = () => {
  const [location, setLocation] = useState('US');
  const [state, handleSubmit] = useForm("xdkndbqb");

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [jobRole, setJobRole] = useState('Graphic Designer');
  const [resume, setResume] = useState(null);
  const [locationPH, setLocationPH] = useState(false);
  const [locationUS, setLocationUS] = useState(false);
  const [meetsRequirements, setMeetsRequirements] = useState(false);

  const handleLocationSwitch = () => {
    setLocation(location === 'US' ? 'PH' : 'US');
  };

  const googleMapIframe = location === 'US' 
    ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3155.876407215121!2d-121.97952852438053!3d37.57639151945844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fc13cb689ed09%3A0x2abc072afed6bcb8!2s37675%20Niles%20Blvd%2C%20Fremont%2C%20CA%2094536%2C%20USA!5e0!3m2!1sen!2sph!4v1692200075015!5m2!1sen!2sph"
    : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3859.2097072462696!2d120.99378977475415!3d14.491608688986372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397cff3448fe381%3A0xf4f092d112e2d74f!2s38%20Editorial%2C%20Para%C3%B1aque%2C%201700%20Metro%20Manila%2C%20Philippines!5e0!3m2!1sen!2sph!4v1692200111343!5m2!1sen!2sph";

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    // Create a FormData object to include the resume file
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('job_role', jobRole);
    formData.append('resume', resume);
    formData.append('location_ph', locationPH);
    formData.append('location_us', locationUS);
    formData.append('meets_requirements', meetsRequirements);

    // Send the form data to Formspree using handleSubmit
    await handleSubmit(formData);

    // Reset form fields
    setName('');
    setEmail('');
    setJobRole('Graphic Designer');
    setResume(null);
    setLocationPH(false);
    setLocationUS(false);
    setMeetsRequirements(false);
  };

  const itemVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (
    <>
      {/* Jumbotron Section */}
      <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5">
        <div>
          <h1 className="display-4 fw-bold">DSS Careers</h1>
          <p className="lead">Your trusted partner in Digital Transformation.</p>
        </div>
      </Container>

      {/* Career Cards Section */}
      <div className="career-container-fluid career-container position-relative">
        <div className="svg-background"></div> {/* SVG Background */}
        <Container fluid className="py-5">
          <Row>
            {[
              {
                title: 'Graphic Designer',
                icon: faPaintBrush,
                description: 'Graphic Designers create compelling visual content using tools like Figma, Canva, video editing software, and Photoshop. They craft eye-catching graphics for both digital and print media, ensuring brand consistency and visual appeal across all platforms.'
              },
              {
                title: 'Virtual Assistant',
                icon: faBriefcase,
                description: 'Virtual Assistants handle a range of administrative tasks, including cold calling, managing emails, scheduling, and customer support. They possess strong communication skills in English and provide essential support to enhance the efficiency of our operations.'
              },
              {
                title: 'Web Developer',
                icon: faLaptopCode,
                description: 'Web Designers build responsive and user-friendly websites using platforms like WordPress and frameworks like React. Knowing languages like Javascript and Typescript. They focus on creating aesthetically pleasing designs that provide a seamless user experience while adhering to client specifications.'
              },
              {
                title: 'App Developer',
                icon: faMobileAlt,
                description: 'App Developers create and maintain high-quality mobile applications using languages like Dart, JavaScript, Python, or TypeScript. They ensure apps are functional, user-friendly, and visually appealing, keeping up with the latest trends in mobile technology.'
              },
            ].map((job, index) => (
              <Col md={12} lg={6} key={index} className="mb-4">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={itemVariants}
                  transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
                >
                  <Card className="h-100 career-card text-left">
                    <Card.Body>
                      <Card.Title className="career-title d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={job.icon} className="career-icon me-3" />
                      </Card.Title>
                      <Card.Text className="fw-bold career-title">{job.title}</Card.Text>
                      <Card.Text className="career-text">{job.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      {/* Testimonials Section */}
      <section className="py-5 testimonials-section text-white">
        <Container>
          <Row className="justify-content-center text-center mb-4">
            <Col lg={8} className="text-center mx-auto">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={itemVariants}
                transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
              >
                <h2 className=" testimonial-header test-help mb-2">Our Employee Testimonials</h2>
                <p className="testi-1 h5">Hear what our team has to say about working with us!</p>
              </motion.div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md={12} lg={6} className="order-2 order-md-1">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={itemVariants}
                transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
              >
                <div className="text-primary mb-4" style={{ textAlign: 'left' }}>
                  <FontAwesomeIcon icon={faQuoteLeft} className="fa-3x quote-icon" />
                </div>
                <p className="career-test text-center">"Collaborating with a diverse group of professionals each day is both challenging and rewarding, making every project a unique experience."</p>
                <h5 className="fw-bold text-center">Alex Hong</h5>
                <div className="career-test1 text-center">Project Manager</div>
              </motion.div>
            </Col>
            <Col md={12} lg={6} className="order-1 order-md-2">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={itemVariants}
                transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
              >
                <div className="text-primary mb-4" style={{ textAlign: 'left' }}>
                  <FontAwesomeIcon icon={faQuoteLeft} className="fa-3x quote-icon" />
                </div>
                <p className="career-test text-center">"The work environment here is like no other company, with a focus on collaboration and respect. I always enjoy coming into work!"</p>
                <h5 className="fw-bold text-center">Emma Lim</h5>
                <div className="career-test1 text-center">UI / UX & Web Developer</div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Application Form Section */}
      <div className="form-container-fluid position-relative">
        <div className="svg-background"></div> {/* Left SVG Background */}
        <div className="svg-background-flipped"></div> {/* Right Flipped SVG Background */}
        <Container className="py-5 mb-4">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={itemVariants}
            transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
          >
            <h1 className=" comp-title testimonial-header test-help mb-2">Join Our Team</h1>
            <p className="sub-header1 app-form-header h5">
              If you're interested in joining our team and have relevant experience, <br></br>
              please complete the form below.
            </p>
          </motion.div>
          <Row>
            <Col md={12} lg={6} className="career-map-container">
              <div className="mb-3">
                <iframe
                  src={googleMapIframe}
                  width="100%"
                  height="570"
                  style={{ border: '3px solid rgb(68, 161, 255)' }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  title="Office Location Map"
                ></iframe>
              </div>
              <div className="d-flex align-items-center">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input custom-switch-input"
                    type="checkbox"
                    id="locationSwitch"
                    onChange={handleLocationSwitch}
                  />
                  <label
                    className="form-check-label map-switch-label custom-switch-label"
                    htmlFor="locationSwitch"
                  >
                    {location === 'US' ? 'Switch to Manila, PH Office' : 'Switch to Fremont, CA Office'}
                  </label>
                </div>
              </div>
            </Col>
            <Col md={12} lg={6} className="career-form-container">
              <Form onSubmit={handleFormSubmit} className="p-4 bg-light rounded form-container">
                <h3 className="fw-bold mb-4">Application Form</h3>
                <Form.Group controlId="formName" className="mb-3">
                  <Form.Label className="fw-bold">Name</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="name" 
                    placeholder="Enter your name" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)}
                    required 
                  />
                </Form.Group>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label className="fw-bold">Email</Form.Label>
                  <Form.Control 
                    type="email" 
                    name="email" 
                    placeholder="Enter your email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                    required 
                  />
                  <ValidationError prefix="Email" field="email" errors={state.errors} />
                </Form.Group>
                <Form.Group controlId="formJobRole" className="mb-3 position-relative">
                  <Form.Label className="fw-bold">Job Role</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="job_role" 
                    value={jobRole} 
                    onChange={(e) => setJobRole(e.target.value)}
                  >
                    <option>Graphic Designer</option>
                    <option>Virtual Assistant</option>
                    <option>Web Designer</option>
                    <option>App Developer</option>
                  </Form.Control>
                  <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon position-absolute" />
                </Form.Group>
                <Form.Group controlId="formResume" className="mb-3">
                  <Form.Label className="fw-bold">Upload Resume</Form.Label>
                  <Form.Control 
                    type="file" 
                    name="resume" 
                    onChange={(e) => setResume(e.target.files[0])}
                    required 
                  />
                </Form.Group>
                <Form.Group controlId="formLocation" className="mb-3 d-flex">
                  <Form.Check 
                    type="checkbox" 
                    label="PH Team" 
                    name="location_ph" 
                    className="me-3" 
                    checked={locationPH}
                    onChange={(e) => setLocationPH(e.target.checked)}
                  />
                  <Form.Check 
                    type="checkbox" 
                    label="US Team" 
                    name="location_us" 
                    checked={locationUS}
                    onChange={(e) => setLocationUS(e.target.checked)}
                  />
                </Form.Group>
                <Form.Group controlId="formCheckbox" className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    label="I meet the requirements for the job." 
                    name="meets_requirements" 
                    checked={meetsRequirements}
                    onChange={(e) => setMeetsRequirements(e.target.checked)}
                    required 
                  />
                </Form.Group>
                <div className="d-flex justify-content-center">
                  <Button variant="primary" type="submit" className="w-90 btn-career" disabled={state.submitting}>
                    Submit Application
                  </Button>
                </div>
                {state.succeeded && (
                  <p className="text-success mt-3 text-center">Thank you for applying!</p>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Careers;
