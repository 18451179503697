import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { useForm, ValidationError } from '@formspree/react';
import './PathwayPopup.css';

const PathwayPopup = () => {
  const [show, setShow] = useState(false);
  const [state, handleSubmit] = useForm('xbljbjny'); // Replace with your Formspree ID

  const truckIcon = useMemo(() => faTruck, []); // Memoize the truck icon to avoid warnings

  // Trigger popup on first scroll
  useEffect(() => {
    const handleScroll = () => {
      const hasVisited = sessionStorage.getItem('hasVisitedPathwayPopup');
      if (!hasVisited && window.scrollY > 50) {
        setShow(true);
        sessionStorage.setItem('hasVisitedPathwayPopup', 'true');
        window.removeEventListener('scroll', handleScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="pathway-popup-modal"
      dialogClassName="pathway-modal-dialog"
    >
      <Modal.Body className="pathway-popup-body">
        <button className="modal-close-button" onClick={handleClose}>
          &times;
        </button>
        <div className="icon-container-pathway">
          <div className="icon-circle-pathway">
            <FontAwesomeIcon
              icon={truckIcon}
              className="truck-animation"
            />
          </div>
        </div>
        <h2 className="popup-title">Discover Pathway360</h2>
        <p className="pathway-popup-description">
          Pathway360 is your all-in-one logistics solution. Manage shipments with a TMS that includes built-in track-and-trace features, real-time analytics, and streamlined workflows. Subscribe now to learn more!
        </p>
        {state.succeeded ? (
          <p className="success-message-pathway">Thank you for subscribing! We’ll send you updates shortly.</p>
        ) : (
          <Form onSubmit={handleSubmit} className="popup-form">
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Control
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                required
                className="popup-input"
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </Form.Group>
            <Button type="submit" className="popup-button" disabled={state.submitting}>
              Subscribe
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PathwayPopup;
