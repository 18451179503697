import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const PathwayFeatures = () => {
    const features = [
        {
            title: "Customizable for Your Operations",
            description: "Pathway360 adapts to your specific business workflows, ensuring it’s a perfect fit for your operational needs. Make it truly your system.",
            iconName: "bi-tools" // Represents customization
        },
        {
            title: "No Expensive Hardware Needed",
            description: "Unlike other systems, Pathway360 doesn’t require costly GPS devices or specialized scanners. Access it on any device with internet access.",
            iconName: "bi-laptop" // Represents device accessibility
        },
        {
            title: "Paperless Efficiency",
            description: "Replace outdated paper-based processes with streamlined digital workflows that reduce errors and improve productivity.",
            iconName: "bi-file-earmark-text" // Represents digitized documents
        },
        {
            title: "Real-Time Transparency",
            description: "Keep clients informed with live shipment updates and full visibility into every step of the process, ensuring trust and satisfaction.",
            iconName: "bi-clock" // Represents real-time tracking
        },
        {
            title: "Scalable and Secure",
            description: "Built on your custom server, Pathway360 scales with your business while safeguarding sensitive data with advanced security protocols.",
            iconName: "bi-shield-lock" // Represents security and scalability
        }
    ];

    return (
        <div className="container" style={{ marginTop: '5rem', marginBottom: '5rem' }}>
            <div className="custom-services-subheader">
                <h3>Pathway360 Features</h3>
                <span className="custom-line-icon"></span>
            </div>

            <div className="custom-wwo-section mt-3 mb-5">
                <h2>Top Five System Features</h2>
                <p className="insurance-feat">
                Pathway360 is your trusted Transportation Management System partner, offering a customizable platform with built-in Track and Trace to eliminate costly hardware, enhance transparency, and streamline logistics operations.
</p>
            </div>

            <Row xs={1} md={2} lg={5} className="g-4">
                {features.map((feature, idx) => (
                    <Col key={idx}>
                        <Card className="divine-feature-card">
                            <Card.Body>
                                <i className={`bi ${feature.iconName} divine-feature-icon mb-2`} aria-hidden="true"></i>
                                <Card.Title className="about-feature-cards">{feature.title}</Card.Title>
                                <Card.Text>{feature.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default PathwayFeatures;
