import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { motion } from 'framer-motion';
import img1 from './paperless.webp';
import img2 from './clienttrack.webp';
import img3 from './dashboardtrack.webp';
import img4 from './devices.webp';
import img5 from './clientside.webp';

const fadeUpVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const PathwaySections = () => {
    return (
        <Container className="my-5">
            <div className="custom-services-subheader">
                <h3>Transforming Logistics</h3>
                <span className="custom-line-icon"></span>
            </div>

            <div className="custom-wwo-section mt-3 mb-5">
                <h2>Why Choose Pathway360?</h2>
                <p className="insurance-feat">
                    The logistics industry faces increasing demands for transparency, efficiency, and cost savings. Pathway360 addresses these challenges with a fully customizable, paperless, and scalable system that transforms how you manage operations and serve your clients.
                </p>
            </div>

            {/* Eliminate Paper-Based Processes */}
            <Row className="divine-sections align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img1} alt="Eliminate Paper-Based Processes" fluid className="divine-img" />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#1 Eliminate Paper-Based Processes</h2>
                        <p>
                            Did you know that up to 40% of containerized trade still relies on physical documentation? Pathway360 replaces paper-based workflows with efficient digital processes, helping you:
                        </p>
                        <ul className="bullet-points-left">
                            <li><strong>Streamline Operations:</strong> Reduce manual errors and save time with automated workflows.</li>
                            <li><strong>Save Resources:</strong> No more managing 50+ sheets of paper for a single shipment.</li>
                            <li><strong>Enhance Communication:</strong> Share and access documents instantly among stakeholders.</li>
                        </ul>
                        <p><em>Source: McKinsey & Company</em></p>
                    </motion.div>
                </Col>
            </Row>

            {/* Real-Time Shipment Tracking */}
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#2 Real-Time Shipment Tracking</h2>
                        <p>
                            88% of customers say real-time tracking is crucial for a positive shipping experience. Pathway360 delivers with:
                        </p>
                        <ul className="bullet-points-left">
                            <li><strong>Live Updates:</strong> Provide clients with accurate, real-time shipment statuses.</li>
                            <li><strong>Transparency:</strong> Build trust by proactively informing clients of delays or changes.</li>
                            <li><strong>Improved Retention:</strong> Ensure satisfaction and loyalty with reliable tracking tools.</li>
                        </ul>
                        <p><em>Sources: Fretron, ShipBob</em></p>
                    </motion.div>
                </Col>
                <Col lg={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                    <Image src={img2} alt="Real-Time Shipment Tracking" fluid className="divine-img" />
                </Col>
            </Row>

            {/* Enhance Supply Chain Visibility */}
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img3} alt="Enhance Supply Chain Visibility" fluid className="divine-img" />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#3 Enhance Supply Chain Visibility</h2>
                        <p>
                            69% of businesses report gaps in supply chain visibility, leading to blind spots and inefficiencies. Pathway360’s centralized dashboards provide:
                        </p>
                        <ul className="bullet-points-left">
                            <li><strong>Full Operational Insights:</strong> Track every step of the logistics process in one place.</li>
                            <li><strong>Customizable Dashboards:</strong> Tailor the interface to your business’s unique needs.</li>
                            <li><strong>Improved Decision-Making:</strong> Real-time insights help you respond quickly to challenges.</li>
                        </ul>
                        <p><em>Source: Shipsy</em></p>
                    </motion.div>
                </Col>
            </Row>

            {/* Cost-Effective and Hardware-Free */}
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#4 Cost-Effective and Hardware-Free</h2>
                        <p>
                            Pathway360 eliminates the need for expensive GPS devices or scanners. Instead, enjoy a solution that works seamlessly across devices:
                        </p>
                        <ul className="bullet-points-left">
                            <li><strong>No Expensive Hardware:</strong> Access Pathway360 on any device with internet connectivity.</li>
                            <li><strong>Lower Operational Costs:</strong> Save on hardware expenses while improving efficiency.</li>
                            <li><strong>Scalable Pricing:</strong> Choose a plan that grows with your business’s needs.</li>
                        </ul>
                    </motion.div>
                </Col>
                <Col lg={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                    <Image src={img4} alt="Cost-Effective and Hardware-Free" fluid className="divine-img" />
                </Col>
            </Row>

            {/* Fully Customizable System */}
            <Row className="divine-sections align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img5} alt="Fully Customizable System" fluid className="divine-img" />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#5 Fully Customizable System</h2>
                        <p>
                            Pathway360 adapts to your unique business workflows, ensuring it meets your exact needs:
                        </p>
                        <ul className="bullet-points-left">
                            <li><strong>Custom Branding:</strong> Incorporate your logo, colors, and unique identity into the platform.</li>
                            <li><strong>Tailored Workflows:</strong> Adjust processes to align with your operations.</li>
                            <li><strong>Integrated Solutions:</strong> Connect seamlessly with your current systems and tools.</li>
                        </ul>
                    </motion.div>
                </Col>
            </Row>
        </Container>
    );
};

export default PathwaySections;
