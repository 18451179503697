import React, { useState, useEffect, useMemo } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDesktop,
  faCode,
  faPuzzlePiece,
  faCheckCircle,
  faMapMarkerAlt,
  faChartLine,
  faTachometerAlt,
  faPlug,
  faShieldAlt,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import ContactForm from './pathway-contact';

const Pathway360Pricing = () => {
  const initialFormData = useMemo(
    () => ({
      name: '',
      phone: '',
      email: '',
      businessName: '',
      service1: '',
      service2: '',
      service3: '',
      message: '',
      pricingPlanTitle: '',
    }),
    []
  );

  const [formData, setFormData] = useState(initialFormData);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    title: '',
    cost: '',
    features: [],
  });
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleResize = () => {};

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    const mainContent = document.getElementById('main-content');

    if (showModal) {
      document.body.style.overflow = 'hidden';
      if (mainContent) mainContent.classList.add('blur-background');
      if (navbar) navbar.classList.add('blur');
    } else {
      document.body.style.overflow = '';
      if (mainContent) mainContent.classList.remove('blur-background');
      if (navbar) navbar.classList.remove('blur');
      setFormData(initialFormData);
    }
  }, [showModal, initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xrgnrknr', {
        method: 'POST',
        body: new FormData(e.target),
        headers: {
          Accept: 'application/json',
        },
      });
      const data = await response.json();
      if (data.ok) {
        setShowSnackbar(true);
        setTimeout(() => setShowSnackbar(false), 5000);
        setShowModal(false);
      } else {
        alert('Oops! There was a problem with your submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message.');
    }
  };

  const pricingPlans = [
    {
      title: 'Tier 1 (Small Businesses)',
      cost: '$299/month',
      features: [
        'Fully Integrated TMS',
        'Track and Trace for up to 500 Shipments',
        'Role-Based Access',
        'Client Tracking Portal',
        'Real-Time Updates',
        'Basic Analytics and Reporting',
        'Custom Server Hosting',
      ],
    },
    {
      title: 'Tier 2 (Medium Businesses)',
      cost: '$599/month',
      features: [
        'All Tier 1 Features',
        'Track and Trace for up to 2,000 Shipments',
        'Advanced Analytics and Reporting',
        'Custom Integrations (API)',
        'Fleet Management Tools',
        'Priority Support',
      ],
    },
    {
      title: 'Tier 3 (Large Businesses)',
      cost: 'Starting at $1,199/month',
      features: [
        'All Tier 1 and Tier 2 Features',
        'Track and Trace for Unlimited Shipments',
        'Dedicated Account Manager',
        'Custom Workflow Automations',
        'Enterprise-Level Security Enhancements',
        'Customizable Reports and Dashboards',
      ],
    },
  ];

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setFormData({ ...formData, pricingPlanTitle: plan.title });
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <div>
      <div id="main-content">
        <Container
          fluid
          className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5"
          style={{ backgroundColor: 'black' }}
        >
          <div>
            <h1>Pathway360 Pricing</h1>
            <p>Empowering Your Logistics with Real-Time Visibility and Control</p>
          </div>
        </Container>

        {/* Pricing Cards */}
        <Container className="my-5">
          <h1 className="text-center pricing-table-title">SELECT A PLAN</h1>
          <Row>
            {pricingPlans.map((plan, index) => (
              <Col md={12} lg={4} key={index}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={cardVariants}
                >
                  <Card
                    className={`pricing-card ${
                      index === 1 ? 'pricing-card-highlighted' : ''
                    }`}
                  >
                    <Card.Body>
                      <FontAwesomeIcon
                        icon={
                          index === 0
                            ? faDesktop
                            : index === 1
                            ? faCode
                            : faPuzzlePiece
                        }
                        className="pricing-plan-icon"
                      />
                      <p className="pricing-plan-title">{plan.title}</p>
                      <h3 className="pricing-plan-cost ml-auto">{plan.cost}</h3>
                      <Button
                        onClick={() => handlePlanSelect(plan)}
                        className="pricing-plan-purchase-btn"
                      >
                        Select Plan
                      </Button>
                      <Container className="pricing-plan-features-container">
                        <ul className="pricing-plan-features">
                          {plan.features.map((feature, idx) => (
                            <li key={idx} className="pricing-plan-feature-item">
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="text-primary icon-pricing"
                              />{' '}
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </Container>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>

        {/* Features Section */}
        <Container className="mt-5 p-5 bg-light border rounded pricing-con">
          <Row className="justify-content-center">
            <h3 className="what-you text-center mb-4">
              Explore Our Top Features
            </h3>
          </Row>
          <Row className="feature-list">
            {/* Feature 1: Real-Time Tracking */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="3x"
                  className="mb-3"
                />
                <h4>Real-Time Tracking</h4>
                <p>
                  Monitor your shipments in real-time with our advanced tracking
                  system, ensuring complete visibility and control over your logistics.
                </p>
              </motion.div>
            </Col>
            {/* Feature 2: Advanced Analytics */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faChartLine} size="3x" className="mb-3" />
                <h4>Advanced Analytics</h4>
                <p>
                  Leverage comprehensive analytics to gain insights into your
                  operations, optimize performance, and drive strategic decisions.
                </p>
              </motion.div>
            </Col>
            {/* Feature 3: Customizable Dashboards */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faTachometerAlt} size="3x" className="mb-3" />
                <h4>Customizable Dashboards</h4>
                <p>
                  Personalize your dashboards to display the metrics and information
                  that matter most to your business, enhancing usability and efficiency.
                </p>
              </motion.div>
            </Col>
            {/* Feature 4: Seamless Integrations */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faPlug} size="3x" className="mb-3" />
                <h4>Seamless Integrations</h4>
                <p>
                  Integrate effortlessly with your existing tools and systems through
                  our robust API and integration capabilities.
                </p>
              </motion.div>
            </Col>
            {/* Feature 5: Robust Security */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faShieldAlt} size="3x" className="mb-3" />
                <h4>Robust Security</h4>
                <p>
                  Protect your data with enterprise-level security measures,
                  ensuring your information is safe and compliant with industry standards.
                </p>
              </motion.div>
            </Col>
            {/* Feature 6: Automated Reporting */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faFileAlt} size="3x" className="mb-3" />
                <h4>Automated Reporting</h4>
                <p>
                  Streamline your operations with automated reports that provide
                  actionable insights without the manual hassle.
                </p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Contact Form Modal */}
      <ContactForm
        show={showModal}
        handleClose={handleClose}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        selectedPlan={selectedPlan}
      />

      {/* Snackbar */}
      {showSnackbar && <div className="snackbar">Submission successful!</div>}
    </div>
  );
};

export default Pathway360Pricing;
